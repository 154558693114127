<template>
	<div class="sitemanage">
		<h1 class="page_title">{{ $route.meta.title }}</h1>
		<a-form
			class="ant-advanced-search-form"
			:form="form"
			@submit="handleSearch"
		>
			<a-row :gutter="24">
				<a-col :span="8">
					<a-form-item label="公司简称">
						<a-select
							placeholder="请选择公司简称"
							v-decorator="['companyId', { initialValue: companyId }]"
						>
							<a-select-option
								v-for="(item, index) in companyList"
								:key="'company' + index"
								:value="item.id"
							>
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="8">
					<a-form-item label="站点名称">
						<a-input
							v-decorator="['powerStationName']"
							placeholder="请输入站点名称"
							autocomplete="off"
						/>
					</a-form-item>
				</a-col>
				<a-col
					:span="8"
					:style="{
						textAlign: 'right',
						height: '40px',
						lineHeight: '40px'
					}"
				>
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button
						:style="{ marginLeft: '8px' }"
						@click="handleReset"
					>
						重置
					</a-button>
				</a-col>
			</a-row>
		</a-form>

		<a-button type="primary" style="margin-bottom: 16px" @click="addSite">
			+ 新增站点
		</a-button>

		<a-table
			rowKey="id"
			:columns="table_col"
			:data-source="table_data"
			:pagination="table_pagination"
			@change="tableChange"
			:loading="table_loading"
		>
			<span slot="hiddenStatus" slot-scope="text">
				<a-badge :status="text == 0 ? 'error' : 'success'"
						:text="text == 0 ? '隐藏' : '显示'"/>
			</span>
			<template slot="active" slot-scope="text, record">
				<a href="javascript:;" @click="editSite(record)">编辑</a>
				<a-divider type="vertical" />
				<a href="javascript:;" @click="enableSite(record)">
					{{ record.hiddenStatus == 0 ? "显示" : "隐藏" }}
				</a>
				<a-divider type="vertical" />
				<a href="javascript:;" @click="manageSite(record)">管理</a>
			</template>
		</a-table>

		<a-modal
			class="sitemanage_modal"
			:title="visible === 'add' ? '新增站点' : '编辑站点'"
			:visible="visible && true"
			@ok="okModal"
			@cancel="cancelModal"
		>
			<a-form
				:form="formModal"
				:label-col="{ span: 6 }"
				:wrapper-col="{ span: 16 }"
			>
				<a-form-item label="公司简称">
					<a-select
						placeholder="请选择公司简称"
						v-decorator="[
							'companyId',
							{
								rules: [
									{
										required: true,
										message: '请输入公司简称'
									}
								],
								initialValue: modalInfo.companyId
							}
						]"
					>
						<a-select-option
							v-for="(item, index) in companyList"
							:key="'company' + index"
							:value="item.id"
						>
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="站点名称">
					<a-input
						placeholder="请输入站点名称"
						v-decorator="[
							'siteName',
							{
								rules: [
									{
										required: true,
										message: '请输入站点名称'
									}
								],
								initialValue: modalInfo.name
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="站点位置">
					<a-select
						showSearch
						:default-active-first-option="false"
						:show-arrow="false"
						:filter-option="false"
						:not-found-content="null"
						placeholder="请选择站点位置"
						v-decorator="[
							'sitePosition',
							{
								rules: [
									{
										required: true,
										message: '请输入站点位置'
									}
								]
							}
						]"
						@search="addressTipList"
						@select="addressToLnglat"
					>
						<a-select-option
							v-for="(item, index) in siteTipList"
							:key="'sitepos' + index"
							:value="item.district + item.address + item.name"
						>
							{{ item.district + item.name }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="地图选点">
					<div class="sitemanage_mapPosition">
						<a-input
							:style="{
								height: `${box.height}px`,
								visibility: 'hidden'
							}"
							placeholder="地图经纬度"
							v-decorator="[
								'mapPosition',
								{
									rules: [
										{
											required: true,
											message: '请选择地图位置'
										}
									]
								}
							]"
						/>
						<div
							id="mapbox"
							:style="{
								width: `${box.width}px`,
								height: `${box.height}px`
							}"
						></div>
						<img
							src="https://webapi.amap.com/theme/v1.3/markers/b/mark_bs.png"
							alt="marker"
							class="marker"
							width="19px"
							height="32px"
						/>
					</div>
				</a-form-item>
				<a-form-item label="投运时间">
					<a-date-picker
						style="width: 100%"
						v-decorator="[
							'operationTime',
							{
								rules: [
									{
										required: true,
										message: '请选择投运时间'
									}
								],
								initialValue: modalInfo.operationTime
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="装机功率">
					<a-input-number
						:min="1"
						placeholder="请输入装机功率"
						style="width: 200px"
						v-decorator="[
							'installedPower',
							{
								rules: [
									{
										required: true,
										message: '请输入装机功率'
									}
								],
								initialValue: modalInfo.installedPower
							}
						]"
					/>
					kW
				</a-form-item>
				<a-form-item label="站点容量">
					<a-input-number
						:min="1"
						placeholder="请输入站点容量"
						style="width: 200px"
						v-decorator="[
							'capacity',
							{
								rules: [
									{
										required: true,
										message: '请输入站点容量'
									}
								],
								initialValue: modalInfo.capacity
							}
						]"
					/>
					kWh
				</a-form-item>
				<a-form-item label="并网点数量">
					<a-input-number
						:min="1"
						placeholder="请输入并网点数量"
						style="width: 200px"
						v-decorator="[
							'generalCount',
							{
								rules: [
									{
										required: true,
										message: '请输入并网点数量'
									}
								],
								initialValue: modalInfo.generalCount
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="站点照片">
					<div class="avatar" style="position: relative">
						<a-input
							style="width: 102px; height: 102px"
							v-decorator="[
								'imageUrl',
								{
									rules: [
										{
											required: true,
											message: '请选择站点照片'
										}
									],
									initialValue: modalInfo.image
								}
							]"
						>
						</a-input>
						<a-upload
							style="position: absolute; top: 0; left: 0"
							name="avatar"
							list-type="picture-card"
							class="avatar-uploader"
							:show-upload-list="false"
							:before-upload="beforeUpload"
							@change="handleChange"
							:customRequest="customRequest"
						>
							<img v-if="imageUrl" :src="imageUrl" class="avatar-img" alt="avatar" />
							<div v-else>
								<a-icon :type="loading ? 'loading' : 'plus'" />
								<div class="ant-upload-text">Upload</div>
							</div>
						</a-upload>
					</div>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>
<script>
	import { 
		companyList,
		powerStationPage,
		powerStationDisplay,
		powerStationHide,
		powerStationCreate,
		powerStationUpdate,
		powerStationSearch} from "@/api/index";
		
	export default {
		name: "SiteManage",
		data() {
			return {
				// 查询条件
				form: this.$form.createForm(this),
				companyName: "",
				companyId: null,
				// 公司列表
				companyList: [],
				// 列
				table_col: [
					{
						title: "序号",
						dataIndex: "key",
						customRender: (text, record, index) => {
							return (
								(this.table_pagination.current - 1) *
									this.table_pagination.pageSize +
								(index + 1)
							);
						}
					},
					{
						title: "公司简称",
						dataIndex: "companyName",
						customRender: ( record) => {
							return record ? record : this.companyName;
						}
					},
					{
						title: "站点名称",
						dataIndex: "name"
					},
					{
						title: "站点位置",
						dataIndex: "address"
					},
					{
						title: "投运时间",
						dataIndex: "operationTime"
					},
					{
						title: "装机功率/kW",
						dataIndex: "installedPower",
						customRender: record => {
							return record ? this.$utils.toFixed(record / 1000, 2)+"/kW" : "-";
						}
					},
					{
						title: "站点容量/kWh",
						dataIndex: "capacity",
						customRender: record => {
							return record ? this.$utils.toFixed(record / 3600000, 2)+"/kWh" : "-";
						}
					},
					{
						title: "电站状态",
						dataIndex: "hiddenStatus",
						scopedSlots: {
							customRender: "hiddenStatus"
						},
						width: 80
					},
					{
						title: "操作",
						dataIndex: "active",
						scopedSlots: {
							customRender: "active"
						},
						width: 160
					}
				],
				visible: false,
				modalInfo: {},
				formModal: this.$form.createForm(this),
				// 地图实例
				map: null,
				// 地图容器大小
				box: { width: 315, height: 100 },
				// 防抖
				timer: null,
				// 站点位置输入提示列表
				siteTipList: [],
				// 图片文件
				file: null,
				// 上传站点图片
				imageUrl: "",
				loading: false,
				// 省市区
				province: "",
				city: "",
				district: ""
			};
		},
		created() {

		},
		mounted() {
			this.getCompanyList();
		},
		beforeDestroy() {
			this.map && this.map.destroy();
			this.map = null;
		},
		methods: {
			// 站点列表
			getTableList(values) {
				this.table_loading = true;
				
				let params = values || this.form.getFieldsValue();
				powerStationPage({
								companyId: params.companyId
							},{
							current: this.table_pagination.current,
							pageSize: this.table_pagination.pageSize,
							...params,
							companyId: params.companyId == 0 ? null : params.companyId
						})
						.then(res => {
							this.table_data = res.data.list;
							this.table_pagination.total = res.data.total;
						})
						.catch(() => (this.table_data = []))
						.finally(() => (this.table_loading = false));
			},
			
			// 公司列表
			getCompanyList() {
				companyList({}).then(res => {
					this.companyList = res.data;
					
					//默认需要选中第1个
					if(this.companyList.length > 0){
						this.companyId = this.companyList[0].id;
						this.companyName = this.companyList[0].name;
					}
				}).finally(() => {
					this.getTableList();
				});
			},
			
		
			// 搜索
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
					if (!error) {
						if(values.companyId){
							let company = this.companyList.find((n) => n.id == values.companyId);
							this.companyId = company.id;
							this.companyName = company.name;

							this.getTableList(values);
						}else{
							this.$message.error("公司简称不能为空");
						}
					}
				});
			},
			// 重置
			handleReset() {
				this.form.resetFields();
				this.getTableList();
			},
			// 新增站点
			addSite() {
				this.visible = "add";
				// 图片文件
				this.file = null;
				// 上传站点图片
				this.imageUrl = "";
				this.loading = false;
				
				this.$nextTick(() => {
					this.mapInit();
				});
			},
			// 编辑站点
			editSite(record) {
				console.log(record);
				powerStationSearch({
						companyId: this.companyId,
						powerStationId:  record.id
					},{})
					.then(res => {
						this.modalInfo = res.data;
						this.modalInfo = {
							...res.data,
							installedPower: res.data.installedPower / 1000,
							capacity: res.data.capacity / 3600000,
							companyId: this.companyId,
							image: `data:image/png;base64,${res.data.image}`,
						};

						this.imageUrl = this.modalInfo.image;
						this.file = this.dataURLtoFile(this.imageUrl);
						this.visible = "edit";
						
						// 位置str 经纬度 图片
						this.$nextTick(() => {
							this.mapInit(
								this.modalInfo.longitude,
								this.modalInfo.latitude
							);
						});
					});
			},
			// base64转file
			dataURLtoFile(dataurl, filename) {
				var arr = dataurl.split(","),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], filename, { type: mime });
			},
			// 显示隐藏
			enableSite(record) {
				if (record.hiddenStatus == 1) {
					powerStationHide({
								companyId: this.companyId
							},{ 
								id: record.id,
							})
							.then(() => {
								this.$message.success("隐藏成功");
								this.getTableList();
							});
				} else {
					powerStationDisplay({
								companyId: this.companyId
							},{ 
								id: record.id,
							})
							.then(() => {
								this.$message.success("显示成功");
								this.getTableList();
							});
				}
			},
			// 管理电站
			manageSite(record) {
				this.$router.push({
					name: "sitedetail",
					query: { id: record.id, companyId: this.companyId }
				});
			},
			okModal() {
				this.formModal.validateFields((error, values) => {
					if (!error) {
						let formdata = new FormData();
						formdata.append(
							"id",
							this.visible === "edit" ? this.modalInfo.id : null
						);
						
						formdata.append("companyId", values.companyId);
						formdata.append("name", values.siteName);
						formdata.append("address", values.sitePosition);
						
						console.log(values.lnglat)
						formdata.append(
							"longitude",
							values.mapPosition.split(",")[0]
						);
						formdata.append(
							"latitude",
							values.mapPosition.split(",")[1]
						);
						formdata.append(
							"operationTime",
							this.$moment(values.operationTime).format(
								"YYYY-MM-DD"
							)
						);
						formdata.append(
							"installedPower",
							values.installedPower * 1000
						);
						formdata.append("capacity", values.capacity * 3600000);
						formdata.append("generalCount", values.generalCount);
						formdata.append("image", this.file);

						formdata.append("province", this.province);
						formdata.append("city", this.city);
						formdata.append("county", this.district);
						this.confirmLoading = true;

						if(this.visible === "add"){
							powerStationCreate({
								companyId: values.companyId
							},formdata).then(() => {
								this.$message.success("新增成功");
								this.getTableList();
								this.cancelModal();
							})
							.catch(() => (this.confirmLoading = false));
						}else{
							powerStationUpdate({
								companyId: values.companyId
							},formdata).then(() => {
								this.$message.success("编辑成功");
								this.getTableList();
								this.cancelModal();
							})
							.catch(() => (this.confirmLoading = false));
						}
					}
				});
			},
			// modal 取消
			cancelModal() {
				console.log("cancel");
				this.modalInfo = {};
				this.formModal.resetFields();
				this.visible = false;
				clearTimeout(this.timer);
				this.timer = null;
				this.file = null;
				this.confirmLoading = false;
				this.province = "";
				this.city = "";
				this.district = "";
				this.imageUrl = "";
			},
			// 地图初始化 北京[116.397428, 39.90923]
			mapInit(lng = 116.397428, lat = 39.90923) {
				// 初始化
				this.map = new window.AMap.Map("mapbox", {
					center: [lng, lat],
					//设置地图的显示样式
					mapStyle: "amap://styles/normal",
					resizeEnable: true,
					zoom: 5
				});
				this.lnglatToAddress(lng, lat);
				// 缩放 移动 最后都会触发该事件
				this.map.on("moveend", () => {
					console.log("moveend");
					this.siteTipList = [];
					const { lng, lat } = this.map.getCenter();
					this.lnglatToAddress(lng, lat);
				});
			},
			// 经纬度转信息
			lnglatToAddress(lng, lat) {
				window.AMap.plugin("AMap.Geocoder", () => {
					var geocoder = new window.AMap.Geocoder();

					var lnglat = [lng, lat];

					geocoder.getAddress(lnglat, (status, result) => {
						if (status === "no_data") {
							this.$message.info("暂不支持海外坐标");
							this.formModal.setFieldsValue({
								sitePosition: "",
								mapPosition: ""
							});
							this.province = "";
							this.city = "";
							this.district = "";
							return;
						}
						if (status === "complete" && result.info === "OK") {
							const { regeocode } = result;
							this.formModal.setFieldsValue({
								sitePosition: regeocode.formattedAddress,
								mapPosition: [lng, lat].join(",")
							});
							this.province = regeocode.addressComponent.province;
							this.city =
								regeocode.addressComponent.city ||
								this.province;
							this.district = regeocode.addressComponent.district;
						}
					});
				});
			},
			// 位置转经纬度
			addressToLnglat(addr) {
				window.AMap.plugin("AMap.Geocoder", () => {
					var geocoder = new window.AMap.Geocoder();
					geocoder.getLocation(addr, (status, result) => {
						if (status === "complete" && result.info === "OK") {
							// result中对应详细地理坐标信息
							const { location } = result.geocodes[0];
							this.map.setZoomAndCenter(13, [
								location.lng,
								location.lat
							]);
						}
					});
				});
			},
			// 位置输入提示select
			addressTipList(addr) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					new window.AMap.Autocomplete().search(
						addr,
						(status, result) => {
							if (status === "complete" && result.info === "OK") {
								this.siteTipList = result.tips;
							} else {
								this.siteTipList = [];
							}
						}
					);
				}, 500);
			},
			// 上传图片校验
			beforeUpload(file) {
				const isJpgOrPng =
					file.type === "image/jpeg" || file.type === "image/png";
				if (!isJpgOrPng) {
					this.$message.error("You can only upload JPG or PNG file!");
				}
				const isLt2M = file.size / 1024 / 1024 < 5;
				if (!isLt2M) {
					this.$message.error("Image must smaller than 5MB!");
				}
				return isJpgOrPng && isLt2M;
			},
			handleChange(info) {
				console.log(info.file.status)
				if (info.file.status === "uploading") {
					console.log("uploading")
					this.loading = true;
					return;
				}
				if (info.file.status === "done") {
					console.log("上传成功", info);
					this.loading = false;
					// Get this url from response in real world.
				}
			},
			customRequest(info) {
				const { file } = info;
				console.log("custom", file);
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					this.imageUrl = reader.result;
					this.file = file;
					this.formModal.setFieldsValue({ imageUrl: "123" });
				};
			}
		}
	};
</script>
<style lang="scss">
	.sitemanage {
		.ant-advanced-search-form .ant-form-item {
			display: flex;
		}

		.ant-advanced-search-form .ant-form-item-control-wrapper {
			flex: 1;
		}
		.avatar-uploader > .ant-upload {
			width: 128px;
			height: 128px;
		}
		.ant-upload-select-picture-card i {
			font-size: 32px;
			color: #999;
		}

		.ant-upload-select-picture-card .ant-upload-text {
			margin-top: 8px;
			color: #666;
		}
	}
	.sitemanage_modal {
		.ant-modal-body {
			height: 600px;
			overflow-y: auto;
		}
		
		.avatar-img{
			width: 100%; 
			object-fit: contain;
		}
	}
	.sitemanage_mapPosition {
		position: relative;
		#mapbox {
			position: absolute;
			left: 0;
			top: 0;

			.amap-logo {
				display: none !important;
			}
		}
		.marker {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%, -100%);
			pointer-events: none;
			user-select: none;
		}
	}
</style>
